export default {
	status: {
		title: 'Gestión de estados',
		desc: 'Herramientas para gestionar los estados del sistema.',
		routes: {
			statusmap: {
				desc: 'Asignar estado local a global.',
				title: 'Mapa',
			},
			statustext: {
				desc: 'Actualizar los textos de los estados del sistema local.',
				title: 'Textos',
			},
		},
	},
};
