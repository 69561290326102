export default {
	status: {
		title: 'Status management',
		desc: 'Tools for managing system statuses.',
		routes: {
			statusmap: {
				desc: 'Assign local to global status.',
				title: 'Map',
			},
			statustext: {
				desc: 'Maintain the texts of the local system states.',
				title: 'Texts',
			},
		},
	},
};
