export default {
	status: {
		routes: {
			statusmap: {
				desc: 'Ordnen sie lokale zu globalen Status zu.',
			},
			statustext: {
				desc: 'Pflegen sie Texte der lokalen Systemzustände.',
			},
		},
	},
};
